import React, { useEffect, useMemo, useState } from "react";
//import ReactDOM from "react-dom";
// import Slider from "react-slick";
import { CloseButton, Modal } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import uiHelper from "utils/uiHelper";
import { useRouter } from "next/router";
import { getAdsBySize } from "redux/actions/ads.Actions";
import { useDispatch, useSelector } from "react-redux";
// import HtmlParser from "react-html-parser";
//import SimpleBar from "simplebar-react";
// import "simplebar/dist/simplebar.min.css";
import { useWindowSize } from "api/commonConstants";

// import { AutoSizer } from "react-virtualized";
import { baseUrl } from "api/constants";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
  EmailIcon,
  EmailShareButton,
} from "next-share";
import { FormattedMessage } from "react-intl";
import "react-virtualized/styles.css"; // only needs to be imported once
import Head from "next/head";
// import Link from "next/link";
// import ClevertapReact from "utils/clevertapHelper";
//import { AutoSizer } from "react-virtualized";
// import dynamic from "next/dynamic";
// const { AutoSizer } = dynamic(() => import("react-virtualized"));

const FtafatModal = ({ show, fatafatPosts1, onClick, queryIndex }) => {
  const size = useWindowSize();
  // const [sliderIndex, setSliderIndex] = useState(0);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareData, setShareData] = useState({});
  const router = useRouter();
  const dispatch = useDispatch();
  const [fatafatPosts, setFatafatPosts] = useState([]);
  const [skip, setSkip] = useState(1);
  const [urlIndex, setUrlIndex] = useState(0);
  const [fetchMoreFatafat, setFetchMoreFatafat] = useState(false);
  const [skipValue, setSkipValue] = useState(2);
  // const goodMorningClick = (skipValue) => {
  //   console.log("Hey fatafat", skipValue);
  //   fetch(
  //     `${baseUrl}/fatafat/rankFatafat?limit=10&skip=${skipValue}&type=video,text`
  //   )
  //     .then((res) => res.json())
  //     .then((d) => {
  //       console.log("asdasd", d);
  //       setFatafatPosts([...fatafatPosts1, ...d?.result?.entities]);
  //       //setFatafatPosts(d?.result?.entities);
  //       // setfetechingAllPost(false)
  //     });
  // };
  useEffect(() => {
    // console.log("ran", fatafatPosts1);
    setFatafatPosts(fatafatPosts1);
  }, []);
  useEffect(() => {
    // goodMorningClick(skip);
    console.log("hohoho", urlIndex);
    if (queryIndex === 0 && fatafatPosts) {
      window.history.pushState(
        {},
        null,
        `/${fatafatPosts[urlIndex]?.categorySlug}/post/${fatafatPosts[urlIndex]?.postSlug}`
      );
    }
    if ((urlIndex + 9) % 10 == 0 && fatafatPosts.length <= urlIndex + 10) {
      console.log("moreloadLENGTH", fatafatPosts.length);

      console.log("moreload", (urlIndex + 9) / 10);
      console.log("moreload", urlIndex);

      // fetchMoreFatafat((index + 5) / 10);
      // setFetchMoreFatafat(true);
      // setSkipValue((urlIndex + 5) / 10);
      fetchMoreFatafat1((urlIndex + 9) / 10 + 1);
      // setSkipValue(skipValue + 1);
    } else if (urlIndex % 10 == 0 && fatafatPosts.length <= urlIndex + 2) {
      console.log("moreload1LENGTH", fatafatPosts.length);

      console.log("moreload1", (urlIndex + 9) / 10);
      console.log("moreload1", urlIndex);
      fetchMoreFatafat1(urlIndex / 10 + 1);
    }
  }, [urlIndex, []]);

  const handleSwipeUp = () => {
    const cname = `fatafat-body ${urlIndex - 1}`;
    const section = document.getElementsByClassName(cname)[0];
    // console.log(cname);
    // console.log(section);

    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const handleSwipeDown = () => {
    const cname = `fatafat-body ${urlIndex + 1}`;
    const section = document.getElementsByClassName(cname)[0];
    // console.log(cname);
    // console.log(section);

    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  // useEffect(() => {
  //   console.log("show", show)te
  //   if(show){
  //     console.log(`/${ftafatAds[sliderIndex]?.postSlug}`);
  //     router.push(`/${ftafatAds[sliderIndex]?.postSlug}`, "", { shallow: true })
  //   }
  // }, [sliderIndex])
  // const values = [true, "sm-down", "md-down", "lg-down", "xl-down", "xxl-down"];
  const [
    fullscreen,
    //  setFullscreen
  ] = useState(true);
  const slider = React.useRef(null);
  // var settings = {
  //   dots: false,
  //   arrows: false,
  //   infinite: false,
  //   speed: 50,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: false,
  //         infinite: false,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         initialSlide: 1,
  //         dots: false,
  //         infinite: false,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         dots: false,
  //         infinite: false,
  //       },
  //     },
  //   ],
  // };

  const MobileFooter = () => {
    return (
      <>
      <Head>
      <link
          rel="preconnect"
          crossOrigin="true"
          href="https://static.chartbeat.com"
        ></link>

        <script
          async
          dangerouslySetInnerHTML={{
            __html: `
            (function () {
              var _sf_async_config = window._sf_async_config = (window._sf_async_config || {});
              _sf_async_config.uid = 60355;
              _sf_async_config.domain = 'thelallantop.com';
              _sf_async_config.useCanonical = true;
              _sf_async_config.useCanonicalDomain = true;
              _sf_async_config.sections = 'home'; 
              _sf_async_config.authors = '';
              function loadChartbeat() {
                  var e = document.createElement('script');
                  var n = document.getElementsByTagName('script')[0];
                  e.type = 'text/javascript';
                  e.async = true;
                  e.src = '//static.chartbeat.com/js/chartbeat.js';
                  n.parentNode.insertBefore(e, n);
              }
              loadChartbeat();
          })();
       `,
          }}
          strategy="afterInteractive"
          id="chartbeat"
          type="text/javascript"
        ></script>
      </Head>
        <div class="line-1"></div>
        <div className="mobile-footer">
          <div className="share-btn-mob">
            <a
              style={{ display: "flex", justifyContent: "center" }}
              onClick={() => handleShare(fatafatPosts1[urlIndex])}
            >
              <span
                className="icon-share footer-items"
                aria-label="Share Button"
              >
                <FormattedMessage
                  id="share"
                  defaultMessage="Read Full Article"
                />
              </span>
            </a>
          </div>

          <div className="read-more-mob">
            <div>
              <a
                passHref
                href={`/${fatafatPosts[urlIndex]?.categorySlug}/post/${fatafatPosts[urlIndex]?.postSlug}`}
              >
                <a className="ftafat-link ms-auto">
                  <FormattedMessage
                    id="read_full_news"
                    defaultMessage="Read Full Article"
                  />
                </a>
              </a>
              <span
                class="icon-arrow-right"
                style={{ color: "#d9368b" }}
              ></span>
            </div>
          </div>
        </div>
        <div class="line-1"></div>
      </>
    );
  };
  window.addEventListener("popstate", function (event) {
    console.log("popstate fired!", event);

    location.replace("/");
  });

  const handleShare = (postData) => {
    const share = {
      title: postData?.title,
      text: postData?.title,
      image: postData?.headingImageUrl,
    };

    share.url = `https://www.thelallantop.com/${postData?.categorySlug}/post/${postData?.postSlug}`;

    console.log("share: ", postData);

    setShareData(share);
    // console.log("navigator", navigator);
    if (!navigator || !navigator.share) {
      setOpenShareModal(true);
      // console.log("Platform not supported!");
    } else {
      uiHelper.share(share);
      // console.log("Platform not supported!1", share);

      //setOpenShareModal(true);

      // console.log("Platform not supporteds!");
    }
  };
  const {
    //adPlacement300_300,
    //adPlacement300_250,
    //adPlacement300_600,
    //adPlacement970_250,
    adPlacement480x320,
  } = useSelector(({ AdsReducer }) => AdsReducer);
  useEffect(() => {
    dispatch(getAdsBySize());
  }, [dispatch]);

  const ftafatAds = useMemo(() => {
    if (!fatafatPosts?.length) {
      return [];
    }

    let newArray = [...fatafatPosts];
    newArray = newArray.map((item, index) => ({
      ...item,
      countindex: index + 1,
    }));
    if (!!adPlacement480x320?.length) {
      newArray?.splice(3, 0, {
        _id: "ad",
        image_url: adPlacement480x320[0]?.image_url,
        redirect_url: adPlacement480x320[0]?.redirect_url,
      });
      newArray?.splice(7, 0, {
        _id: "ad",
        image_url: adPlacement480x320[1]?.image_url,
        redirect_url: adPlacement480x320[1]?.redirect_url,
      });
    }
    return newArray;
  }, [fatafatPosts, adPlacement480x320]);
  const fetchMoreFatafat1 = async (index) => {
    console.log(
      "skip value is",
      `${baseUrl}/post/allFatafat?limit=10&skip=${index}&type=text`
    );
    const res = await fetch(
      `${baseUrl}/post/allFatafat?limit=10&skip=${index}&type=text`
    );
    const newPost = await res.json();
    setFatafatPosts([...fatafatPosts, ...newPost?.result?.entities]);
    console.log("d?.result?.entities", newPost?.result?.entities);
    setFetchMoreFatafat(false);

    // setfetechingAllPost(false)
  };

  // useEffect(() => {
  //   const fetchMoreFatafat1 = async (index) => {
  //     console.log(
  //       "skip value is",
  //       `${baseUrl}/post/allFatafat?limit=10&skip=${index + 1}&type=text`
  //     );
  //     const res = await fetch(
  //       `${baseUrl}/post/allFatafat?limit=10&skip=${index + 1}&type=text`
  //     );
  //     const newPost = await res.json();
  //     setFatafatPosts([...fatafatPosts1, ...newPost?.result?.entities]);
  //     console.log("d?.result?.entities", newPost?.result?.entities);
  //     setFetchMoreFatafat(false);

  //     // setfetechingAllPost(false)
  //   };

  //   fetchMoreFatafat && fetchMoreFatafat1(skip);

  //   return () => {
  //     // this now gets called when the component unmounts
  //   };
  // }, [fetchMoreFatafat]);
  const ftafatCards = document.querySelectorAll(".fatafat-body");
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          var index = parseInt(entry.target.className.split(" ")[1]);
          // console.log("index is", index);
          // if ((index + 5) % 10 === 0 && fatafatPosts.length < index + 10) {
          //   console.log("moreload", (index + 5) / 10);
          //   // fetchMoreFatafat((index + 5) / 10);
          //   setFetchMoreFatafat(true);
          //   setSkipValue((index + 5) / 10);
          // }
          setUrlIndex(index);
        }
      });
    },
    { threshold: 0.7 }
  );
  ftafatCards.forEach((entry) => {
    observer.observe(entry);
  });

  // const queryIndex = router.query?.index ? parseInt(router.query.index) : 0;
  const postData = useMemo(() => {
    return queryIndex && fatafatPosts1[queryIndex];
  }, [queryIndex]);
  // const postData = fatafatPosts[queryIndex];

  return (
    <>
      <Modal
        dialogClassName="ftafat-modal"
        show={show}
        fullscreen={fullscreen}
        onHide={onClick}
      >
        <Modal.Body>
          {fatafatPosts?.map((postData1, index) => (
            <div key={index} className="fatafat-container">
              <div key={index} className={`fatafat-body ${index} `}>
                <div className="ftafat-image-container">
                  <div className="ftafat-image">
                    <img
                      src={postData1?.headingImageUrl}
                      alt="image"
                      width="100%"
                      height="auto"
                    />
                  </div>
                  <div className="ftafat-close-button">
                    <div className="close-btn">
                      <CloseButton variant="white" onClick={onClick} />
                    </div>
                  </div>
                </div>
                <div className="ftafat-content-container">
                  <div className="ftafat-heading">
                    <h3>{postData1?.title}</h3>
                  </div>
                  <div className="ftafat-body">
                    {postData1?.fatafatDescription}
                  </div>
                </div>
                <div className="footer-mobile">
                  <MobileFooter />
                  <div className="slide-up">
                    <img
                      src="/assets/images/slide-up.gif"
                      width="30px"
                      height="30px"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-desktop-buttons">
                <div className="close-btn">
                  <CloseButton variant="white" onClick={onClick} />
                </div>
                <div className="swipe-icon-buttons">
                  <img
                    src="/assets/images/icons8-chevron-up-90.png"
                    width="40px"
                    height="40px"
                    className="swipe-icon"
                    onClick={handleSwipeUp}
                    disabled={urlIndex == 0 ? true : false}
                  />
                  <img
                    src="/assets/images/icons8-chevron-down-90.png"
                    width="40px"
                    height="40px"
                    className="swipe-icon"
                    onClick={handleSwipeDown}
                  />
                </div>
              </div>
            </div>
          ))}
        </Modal.Body>
      </Modal>

      <Modal
        show={openShareModal}
        fullscreen={false}
        centered
        size="sm"
        onHide={() => setOpenShareModal(false)}
      >
        <div className="row p-2">
          <div className="col-6">Social Share</div>
          <div className="col-6 text-end">
            <div role={"button"} onClick={() => setOpenShareModal(false)}>
              <span className="icon-close"></span>
            </div>
          </div>
        </div>

        <Modal.Body>
          <div align="center">
            <FacebookShareButton quote={shareData.text} url={shareData.url}>
              <FacebookIcon className="me-1" size={32} round />
            </FacebookShareButton>

            <TwitterShareButton title={shareData.text} url={shareData.url}>
              <TwitterIcon className="me-1" size={32} round />
            </TwitterShareButton>

            <EmailShareButton
              url={shareData.url}
              subject={shareData.text}
              body={shareData.url}
            >
              <EmailIcon className="me-1" size={32} round />
            </EmailShareButton>

            <LinkedinShareButton title={shareData.text} url={shareData.url}>
              <LinkedinIcon className="me-1" size={32} round />
            </LinkedinShareButton>
            <WhatsappShareButton url={shareData.url} title={shareData.text}>
              <WhatsappIcon className="me-1" size={32} round />
            </WhatsappShareButton>
            <hr />
            <span style={{ fontSize: "13px" }} className="text-dark">
              <span id="cpy">Copy</span> to Clipboard{" "}
              <i className="las la-clipboard la-lg"></i>
            </span>
            <input
              style={{ fontSize: "13px" }}
              onClick={() => {
                let et = document.getElementById("copy");
                et.select();
                document.execCommand("copy");
                document.getElementById("cpy").textContent = "Copied";
              }}
              id="copy"
              value={shareData.url}
              type="text"
              readOnly
              className="form-control"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

// {/* <Slider ref={slider} {...settings}>
//   {ftafatAds?.map((postData) => {
//     if (postData?._id === "ad") {
//       return (
//         <div key={postData?._id} className="ftafat-card">
//           <div className="ftafat-ads">
//             <div className="sponser-ads">
//               <a href={postData?.redirect_url || "#"}>
//                 <img src={postData?.image_url} alt="ads" />
//               </a>
//             </div>
//           </div>
//         </div>
//       );
//     } else {
//       return (
//         <div key={postData?._id}>
//           <div className="ftafat-card">
//             <div className="ftafat-image ratio ratio-16x9">
//               <img
//                 src={postData?.headingImageUrl}
//                 width="616"
//                 height="420"
//                 alt=""
//               />
//             </div>
//             <div className="share-count">
//               <div className="counts-sec">
//                 <div className="count-box">
//                   {postData.countindex > 9
//                     ? `${postData.countindex}`
//                     : `0${postData.countindex}`}
//                   /
//                   {fatafatPosts?.length > 9
//                     ? `${fatafatPosts?.length}`
//                     : `0${fatafatPosts?.length}`}
//                 </div>
//                 <div className="fatafat-date">
//                   {formatDate(postData?.createdAt).date}
//                 </div>
//               </div>
//               <div className="share-btn">
//                 <a onClick={() => handleShare(postData)}>
//                   <span className="icon-share" aria-label="Share Button"></span>
//                 </a>
//               </div>
//             </div>
//             <div className="ftafat-news-box">
//               <div style={{ flex: "1 1 auto" }}>
//                 <AutoSizer>
//                   {({ height, width }) => (
//                     <Scrollbars
//                       autoHide
//                       autoHideTimeout={1000}
//                       autoHideDuration={200}
//                       renderView={(props) => (
//                         <div
//                           {...props}
//                           style={{
//                             ...props.style,
//                             overflowX: "hidden",
//                           }}
//                         />
//                       )}
//                       style={{ width, height }}
//                       className="ftafat-scrollbar"
//                     >
//                       <h3>
//                         {
//                           postData?.title
//                           // ?.substring(0, 60)
//                           // .concat("...")
//                         }
//                       </h3>
//                       <div className="ftafat-content">
//                         {/* {HtmlParser(postData?.articleHTML)} */}
//                         {postData?.fatafatDescription}
//                       </div>
//                       <div className="sticky-read-more">
//                         <a passHref href={postData?.permalink}>
//                           <a className="ftafat-link ms-auto">
//                             <FormattedMessage
//                               id="read_full_news"
//                               defaultMessage="Read Full Article"
//                             />
//                           </a>
//                         </a>
//                       </div>

//                       <div className="row py-2">
//                         <div className="col-6 text-end">
//                           <button
//                             className="ftafat-prev ftafat-arrow-m"
//                             onClick={() => {
//                               slider?.current?.slickPrev();
//                               setSliderIndex((index) =>
//                                 index === 0 ? 0 : index - 1
//                               );
//                             }}
//                           >
//                             <span className="icon-arrow-left"></span>
//                           </button>
//                         </div>
//                         <div className="col-6 text-start">
//                           <button
//                             className="ftafat-next ftafat-arrow-m"
//                             onClick={() => {
//                               if (
//                                 sliderIndex ===
//                                 ftafatAds.length - 1
//                               ) {
//                                 setSliderIndex(0);
//                                 onClick();
//                                 return;
//                               } else {
//                                 slider?.current?.slickNext();
//                                 setSliderIndex(
//                                   (index) => index + 1
//                                 );
//                               }
//                             }}
//                           >
//                             <span className="icon-arrow-right"></span>
//                           </button>
//                         </div>
//                       </div>
//                     </Scrollbars>
//                   )}
//                 </AutoSizer>
//               </div>
//             </div>
//           </div>
//         </div>
//       );
//     }
//   })}
// </Slider> */}
export default FtafatModal;
